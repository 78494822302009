// IMPORTS
@import (once) "/node_modules/nouislider/dist/nouislider.css";

@import (reference) "../settings.less";

body[data-type="listing"] main {
    background: @sec-color;
    position: relative;

    &:before {
        .full-bg();
        bottom: 0;
        background-color: inherit;
    }
}

// LISTING WRAPPER
.listing-wrapper {
    .grid();
    grid-template-columns: minmax(0, 1fr);
    gap: 32px;

    .navigation-wrapper {
        margin-bottom: 32px;
    }

    .filter-trigger,
    .dropdown-picker {
        margin-top: 14px;
    }

    .pagination {
        margin-top: 24px;
    }
}

// CATEGORY HEADER & DESCRIPTION
.category-header-wrapper {
    gap: 24px;

    &:not(:has(> *)) {
        display: none;
    }

}

.category-headline {
    margin-bottom: 0 !important;
}

.text-expand-wrapper {
    margin-top: 16px;
    position: relative;
    display: flex;
    flex-direction: column;

    &:has(.expandable) {
        padding-bottom: 60px; // button height + gap
    }
}
.text-expand-content {
    max-height: calc(3 * var(--fontSize) * var(--lineHeight));
    overflow: hidden;
    position: relative;


    + button {
        display: none;
        position: absolute;
        bottom: 0;
        .confirm-button();
        height: auto;

        &:before {
            content: "Czytaj więcej";
            font-size: 14px;
        }
    }


    &.expandable {

        + button {
            display: flex;
        }


        &.show {
            max-height: none;

            + button {

                &:before {
                    content: 'Zwiń';
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .category-header-wrapper {
        display: contents;
    }

    .category-description {
        order: 2;
    }
}


// NAVIGATION
.navigation-wrapper {
    display: none;
    flex-wrap: wrap;
    gap: 10px 20px;

    &:has(#pills-container .single-pill),
    &:has(.dropdown-picker) {
        display: flex;
    }
}

.listing-wrapper:has(.products-list:empty) {

    .navigation-wrapper {
        display: none;
    }

    .dropdown-picker {
        display: none;
    }
}

// DROPDOWN PICKER
.dropdown-picker {
    position: relative;
    display: flex;
    position: relative;
    z-index: 10;

    &.show-dropdown {

        .dropdown-button:after {
            transform: scaleY(-1);
        }

        .dropdown-listing {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }

    &:before {
        background-color: @sec-color;
        position: absolute;
        top: -14px;
        left: 8px;
        font-size: 16px;
        font-weight: 700;
        color: @header-color;
        padding: 2px 8px;
        z-index: 11;
        pointer-events: none;
    }

    &[data-dropdown-type="sort"]:before {
        content: 'Sortowanie';
    }

    &[data-dropdown-type="pageSize"] {

        &:before {
            content: 'Ilość na stronie';
        }

        .dropdown-button {
            min-width: 200px;
        }
    }
}

.dropdown-button {
    border: 1px solid #DFC58D;
    color: @font-color;
    min-width: 312px;
    height: 44px;
    text-align: left;
    position: relative;
    padding: 0 62px 0 16px;
    z-index: 1;

    &:after {
        content: "\e910";
        font-family: icomoon;
        position: absolute;
        top: -1px;
        right: -1px;
        width: 44px;
        height: 44px;
        color: @header-color;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:hover {
        border-color: @header-color;
    }
}

.dropdown-listing {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid @border-color;
    border-top: 0;
    .shadow();
    min-width: 100%;
    padding: 5px 0;
    transform: translateY(-5px);
    transition: all .2s ease-in-out;

    span {
        display: block;
    }

    input {
        display: none;

        &:checked + label {
            font-weight: 700;
            color: @header-color;
            text-decoration: none !important
        }
    }

    label {
        display: block;
        padding: 5px 17px;
        user-select: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

// FILTER
.close-filter,
.close-filter-bottom {
    display: none;
}

.filter-trigger {
    border: 1px solid #DFC58D;
    color: @font-color;
    min-width: 312px;
    height: 44px;
    text-align: left;
    position: relative;
    padding: 0 62px 0 16px;
    z-index: 1;
    display: none;

    &:before {
        content: 'Filtrowanie';
        background-color: @sec-color;
        position: absolute;
        top: -14px;
        left: 8px;
        font-size: 16px;
        font-weight: 700;
        color: @header-color;
        padding: 2px 8px;
    }

    svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
    }


    &:hover {
        border-color: @header-color;
    }
}

// STANDARD FILTER
body[data-canvas-filter] {

    &:has(#filter .gs-filtr-container) {

        .navigation-wrapper {
            display: flex;
        }

        .filter-trigger {
            display: block;
        }

        @media (max-width: 1279px) {
            .filter-trigger {
                display: flex;
            }
        }
    }
}

.filter-sorter-wrapper {
    border: 4px solid @border-color;
    border-top: 0;
    border-bottom-width: 1px;
    padding: 24px 16px;
    
    .sorter {

        &:before {
            display: none;
        }
    }

    .sorter-button {
        min-width: 0;
        width: 100%;
    }
}

@media (min-width: 1280px) {
    // FILTER ON CANVAS
    body[data-canvas-filter] {

        #filter {
            position: fixed;
            top: 0;
            z-index: 10000;
            background-color: white;
            width: 290px;
            height: 100vh;
            overflow-y: auto;
            padding: 8px 12px;
            transform: translateX(-100%);
            transition: transform .3s ease-in-out;
            color: white;

            > h3 {
                font-size: 22px;
                margin-bottom: 8px;
                font-weight: bold;
                text-transform: uppercase;
                font-family: @main-font-family;
            }

            .gs-filtr-container {

                label {

                    &:before {
                        border-color: lighten(@font-color, 30%) !important;
                    }
                }
            }

            .gs-filtr-container {
                border: 0;
                padding-left: 0;
                padding-right: 0;
                padding-top: 0 !important;
                padding-bottom: 8px !important;

                > strong {
                    display: block;
                    color: @header-color;
                    padding: 10px 0;
                    font-weight: 700;

                    span {
                        display: none;
                    }

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }

                input {
                    display: none;
                }

                    input:checked + label {

                        &:before {
                            border-color: @sec-color;
                            border-width: 2px;
                        }

                        &:after {
                            border-bottom: 2px solid @header-color;
                            border-left: 2px solid @header-color;
                            width: 10px;
                        }
                    }

                label {
                    display: block;
                    padding: 7px 0 7px 29px;
                    line-height: 15px;
                    position: relative;
                    cursor: pointer;
                    user-select: none;
                    color: @font-color;

                    &:active {

                        &:before {
                            border-radius: 50%;
                        }
                    }

                    &:hover {

                        &:before {
                            border-width: 2px;
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 6px;
                        left: 0;
                        width: 18px;
                        height: 18px;
                        border: 1px solid @header-color;
                        transition: border-radius .1s ease-in-out;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        transform-origin: top left;
                        border: 0;
                        height: 7px;
                        width: 0;
                        left: 3px;
                        top: 15px;
                        transform: rotate(-45deg);
                        transition: width .2s ease;
                    }
                }

                &:not(.colors) {

                    ul {
                        max-height: 174px;
                        overflow: hidden;
                        overflow-y: auto;
                        scrollbar-width: thin;

                        &::-webkit-scrollbar {
                            width: 10px;
                            height: 8px;
                            background-color: @border-color;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: darken(@border-color, 20%);
                        }
                    }
                }

                &.colors {

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 4px;
                    }

                    li {
                        display: block;
                    }

                    label {
                        font-size: 0;
                        padding: 0;
                        width: 34px;
                        height: 34px;
                        border: 2px solid @border-color;
                        outline: 4px solid white;
                        outline-offset: -5px;

                        &:before,
                        &:after {
                            display: none;
                        }

                        &:hover {
                            border-color: @header-color;
                        }
                    }

                    input:checked + label {
                        border-color: @header-color;
                        outline-width: 3px;
                    }
                }
            }

            .price-filtering {

                #price-filter-amounts {
                    display: flex;

                    label,
                    i {
                        font-style: normal;
                        line-height: 36px;
                        margin-right: 8px;

                        &:last-of-type {
                            margin-left: 8px;
                        }
                    }

                        label:before {
                            display: none;
                        }

                    input {
                        display: block;
                        width: auto;
                        height: 36px;
                        width: 46px;
                        border: 1px solid @border-color;
                        background: transparent;
                        text-align: center;
                        padding: 0;
                        text-align: center;
                        flex-grow: 1;

                        &:nth-of-type(1) {
                            margin-right: 8px;
                        }
                    }
                }

                #price-filter-handles {
                    width: 85%;
                    margin: 10px auto 20px;
                }

                .noUi-target {
                    box-shadow: none;
                }

                .noUi-horizontal .noUi-handle {
                    box-shadow: none;
                    cursor: grab;

                    &.noUi-active {
                        cursor: grabbing;
                    }
                }
            }
        }

        #container.show-side-filter #filter {
            transform: translateX(0);
        }

        #container .inner-wrapper {

            &:before {
                content: '';
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.25);
                z-index: 10000;
                opacity: 0;
                visibility: hidden;
                transition: opacity .2s ease-in-out;
            }
        }

        #container.show-side-filter .inner-wrapper:before {
            visibility: visible;
            opacity: 1;
        }

        .price-filtering #price-filter-amounts i {
            color: @header-color;
        }
    }

    #filter .gs-filtr-container {

        &.no-filters-found {

            &:after {
                content: 'brak filtrów...';
                color: @error-color;
                display: block;
            }
        }

        > input {
            height: 30px;
            margin-bottom: 5px;
            padding: 0 10px;
            font-size: 13px;
        }

        label {

            &.hide {
                display: none !important;
            }
        }
    }
}

.price-filtering {

    .noUi-target {
        box-shadow: none;
    }

    .noUi-horizontal .noUi-handle {
        box-shadow: none;
        cursor: grab;
        width: 16px;
        height: 16px;
        background-color: @error-color;
        border-radius: 3px;
        border: 0;

        &:before,
        &:after {
            display: none;
        }

        &.noUi-active {
            cursor: grabbing;
        }

        &.noUi-handle-upper {
            right: 0;
        }
    }
}

#price-filter-handles {
    margin: 18px auto 17px;
    height: 4px;
    background-color: lighten(#DFC58D, 5%);
    border: 0;
    border-radius: 0;
}

#price-filter-amounts {
    display: flex;
    justify-content: space-between;

    label,
    i {
        font-style: normal;
        line-height: 33px;
        color: @font-color;
    }

    label {
        padding: 0 !important;
    }

    label:before {
        display: none;
    }

    input {
        display: block;
        height: 33px;
        border: 1px solid @border-color;
        background: transparent;
        text-align: center;
        padding: 0;
        text-align: center;
        width: 68px;
    }
}

#pills-container {

    &:empty {
        display: none;
    }
    
    &:not(:empty) {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        width: 100%;
        gap: 20px;
    }
}

// LISTING PRODUCTS
body[data-type="listing"] .products-list { // listing only
    display: flex;
    flex-direction: column;
    gap: 100px;

    &:empty {
        display: none;
    }
}

body[data-type="listing"] .product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;

    .product-image {
        width: 31.25%;
        max-width: 450px;
        aspect-ratio: 1;
        transition: transform .1s ease-in-out;
        position: relative;
    }

    .product-info-wrapper {
        flex: 1;
        margin-inline: 11.11% 0;
        padding: 68px 64px 68px 147px;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &:nth-child(odd) {
        background: #F4CB63;

        .product-image {
            margin-block: 41px;
        }

        .product-info-wrapper {
            margin-block: 64px;
            order: -1;
            margin-inline: 0 11.11%;
            padding: 68px 147px 68px 64px;
        }

        &:before {
            .full-bg();
            background: inherit;
        }
    }
}

.product-info-wrapper {
    .bottom-shadows();

    .product-badges {
        left: initial;
        right: 0;
        align-items: flex-end;
        z-index: 6;
    }

    .product-name {
        color: @header-color;
        font-size: 24px;
        font-weight: 700;
        font-family: @sec-font-family;
        z-index: 6;
    }

    .product-desc {
        font-size: 17px;
        margin-top: 16px;
        z-index: 6;
    }

    .product-price {
        margin-top: 24px;
        z-index: 5;

        &:before {
            content: "";
            position: absolute;
            inset: 0;
            background: white;
            z-index: -1;
        }

        > b,
        > del {
            display: inline-block;

            &:after {
                content: " zł";
            }
        }

        > b {
            font-size: 24px;
        }

        > del {
            margin-left: 20px;
            color: rgba(32,33,36,0.7);
        }
    }

    > .see-more {
        z-index: 7;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        text-align: center;
        color: white;
        font-size: 18px;
        font-weight: 800;
        letter-spacing: 0.05em;
        .bottom-shadows();
        height: 56px;

        span {
            height: 100%;
            padding: 0 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #3D9BE9;
            transition: transform 0.15s ease-out;
        }

        &:before {
            transform: rotate(-7.08deg);
            z-index: -1;
            bottom: -5%;
        }

        &:after {
            transform: rotate(7.08deg);
            z-index: -1;
            bottom: -5%;
        }

        @media (min-width: 1280px) {
            &:hover span {
                background-color: darken(#3D9BE9, 5%);
                transform: scale(1.05) translateY(-2px);
                border-radius: 5px;
            }
        }
    }

    .product-wishlist {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;
        color: white;
        background: @main-color;
        transition: background-color 0.05s ease-in-out;

        &:before {
            .small-icon();
            content: "\e916";
            font-size: 24px;
            position: relative;
            top: 1px;
        }

        &.existing {
            background: @error-color;
        }

        @media(min-width: 1280px) {
            &:hover {
                background: darken(#FFD401, 5%);
            }

            &:hover.existing {
                background: darken(@error-color, 5%);
            }
        }
    }
}

// IE FALLBACK
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .sorter-button:before {
        display: none;
    }
}